import React from "react";
import mock01 from "../assets/images/mock01.png";
import mock02 from "../assets/images/mock02.png";
import mock03 from "../assets/images/mock03.png";
import mock04 from "../assets/images/mock04.png";
import mock05 from "../assets/images/mock05.png";
import mock06 from "../assets/images/mock06.png";
import mock07 from "../assets/images/mock07.png";
import mock08 from "../assets/images/mock08.png";
import mock09 from "../assets/images/mock09.png";
import mock10 from "../assets/images/mock10.png";
import "../assets/styles/Project.scss";

function Project() {
  return (
    <div className="projects-container" id="projects">
      <h1>Personal Projects</h1>
      <div className="projects-grid">
        <div className="project">
          <a href="https://github.com/kdenaeem/GC_frontend" target="_blank" rel="noreferrer">
            <img src={mock10} className="zoom" alt="thumbnail" width="100%" />
          </a>
          <a href="https://github.com/kdenaeem/GC_frontend" target="_blank" rel="noreferrer">
            <h2>The Global Condition</h2>
          </a>
          <p>
            A full-stack application that visualizes global news trends through
            an interactive world map, combining React and D3.js for the frontend
            with FastAPI and NLTK for news analysis and processing
          </p>
        </div>
        <div className="project">
          <a
            href="https://github.com/kdenaeem/insteadofextension"
            target="_blank"
            rel="noreferrer"
          >
            <img src={mock09} className="zoom" alt="thumbnail" width="100%" />
          </a>
          <a
            href="https://github.com/kdenaeem/insteadofextension"
            target="_blank"
            rel="noreferrer"
          >
            <h2>insteadOf Google Chrome Extension</h2>
          </a>
          <p>
            Designed and developed a chrome extension for productivity to help
            you manage procrastination by suggesting items from your ToDo list
            MongoDB, React JS, Express JS, Node JS
          </p>
        </div>
        <div className="project">
          <a
            href="https://github.com/kdenaeem/attendly"
            target="_blank"
            rel="noreferrer"
          >
            <img src={mock07} className="zoom" alt="thumbnail" width="100%" />
          </a>
          <a
            href="https://github.com/kdenaeem/attendly"
            target="_blank"
            rel="noreferrer"
          >
            <h2>Attendly: Attendance powered by Facial Recognition</h2>
          </a>
          <p>
            Built a facial recognition app using Flutter, Tensorflow and facial
            recognition for recording attendance.
          </p>
        </div>

        <div className="project">
          <a
            href="https://github.com/kdenaeem/MLP-Learning-Algorithm"
            target="_blank"
            rel="noreferrer"
          >
            <img src={mock04} className="zoom" alt="thumbnail" width="100%" />
          </a>
          <a
            href="https://github.com/kdenaeem/MLP-Learning-Algorithm"
            target="_blank"
            rel="noreferrer"
          >
            <h2>Multilayer Perceptron algorithm for Weather forecasting</h2>
          </a>
          <p>
            Built a Neural Network based weather forecasting system using
            Python, focusing on predicting the temperatures in San Deigo. The
            project uses MLP algorithm with gradient descent and momentum, built
            using NumPy and Matplotlib.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Project;
