import React from "react";
import "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReact,
  faDocker,
  faPython,
} from "@fortawesome/free-brands-svg-icons";
import Chip from "@mui/material/Chip";
import "../assets/styles/Expertise.scss";

const labelsFirst = [
  "React",
  "TypeScript",
  "JavaScript",
  "HTML5",
  "CSS3",
  "SASS",
  "Flask",
  "Python",
  "SQL",
  "FastAPI",
  "SpringBoot",
  "Postman",
];

const labelsSecond = [
  "Git",
  "BitBucket",
  "Kubernetes",
  "NexusIQ",
  "SonarQube",
  "CheckMarx",
  "Docker",
  "AWS",
  "Linux",
  "Pandas",
  "Splunk"
];

const labelsThird = [
  "OpenAI",
  "LangChain",
  "Qdrant",
  "Hugging Face",
  "LlamaIndex",
  "NLTK",
  "TensorFlow",
  
];

function Expertise() {
  return (
    <div className="container" id="expertise">
      <div className="skills-container">
        <h1>Expertise</h1>
        <div className="skills-grid">
          <div className="skill">
            <FontAwesomeIcon icon={faReact} size="3x" />
            <h3>Full Stack Web Development</h3>
            <p>
              I have built a diverse array of web applications from scratch
              using modern technologies such as React, Flask and Node. I have
              experience in frontend + backend developement and optimised API
              designs
            </p>
            <div className="flex-chips">
              <span className="chip-title">Tech stack:</span>
              {labelsFirst.map((label, index) => (
                <Chip key={index} className="chip" label={label} />
              ))}
            </div>
          </div>

          <div className="skill">
            <FontAwesomeIcon icon={faDocker} size="3x" />
            <h3>DevOps & Automation</h3>
            <p>
              From working on vast applications such as Visas authortisation app
              in container based environment to deploying and managing my own
              containers. I have built an expertise in DevOps and Automation
            </p>

            <div className="flex-chips">
              <span className="chip-title">Tech stack:</span>
              {labelsSecond.map((label, index) => (
                <Chip key={index} className="chip" label={label} />
              ))}
            </div>
          </div>

          <div className="skill">
            <FontAwesomeIcon icon={faPython} size="3x" />
            <h3>GenAI & LLM</h3>
            <p>
              As someone who is interested in GenAI and LLMs, I have built chatbots, creating embedding models, building Neural Networks
            </p>
            <div className="flex-chips">
              <span className="chip-title">Tech stack:</span>
              {labelsThird.map((label, index) => (
                <Chip key={index} className="chip" label={label} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expertise;
